import React, { useState, useEffect } from "react";
import { useStripe, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import { Skeleton, Box } from "@mui/material";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import paymentIntentCallAPI from "store/paymentIntent/action";
import { PAYMENT_INTENT } from "store/paymentIntent/constant";

function ApplePayComponent(props) {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isApplePayAvailable, setIsApplePayAvailable] = useState(false);
  const [loading, setLoading] = useState(true); // Track loading state
  const dispatch = useDispatch();
  const [clientSecret, setClientSecret] = useState();

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "GB",
        currency: "gbp",
        total: {
          label: "Total",
          amount: Math.round(props?.amount * 100), // Amount in cents
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        setLoading(false); // Stop skeleton loader when payment check is done
        if (result && result.applePay) {
          setPaymentRequest(pr);
          setIsApplePayAvailable(true);

          dispatch(paymentIntentCallAPI(PAYMENT_INTENT, {}, {
            run: true,
            handleFunction: (res) => setClientSecret(res?.data?.stripe_ref?.client_secret)

          }))
          // dddd===
          pr.on("paymentmethod", async (event) => {
            try {
              // Confirm payment directly using Stripe's backend capabilities
              const { error } = await stripe.confirmPayment({
                confirmParams: {
                  payment_method: event.paymentMethod.id, // Automatically provided by Apple Pay
                },
                clientSecret: clientSecret, // Client secret obtained from your backend
              });

              if (error) {
                console.error("Payment failed:", error);
                toast.error("Payment failed: " + error.message);
                event.complete("fail"); // Notify the UI that the payment failed
              } else {
                console.log("Payment succeeded!");
                toast.success("Payment succeeded!");
                event.complete("success"); // Notify the UI that the payment succeeded
                props.handleCheckout(); // Trigger post-payment logic
              }
            } catch (err) {
              console.error("Error handling payment:", err);
              toast.error("Error handling payment: " + err.message);
              event.complete("fail"); // Notify the UI that the payment failed
            }
          });
          // dddd===


          // Add event listener for the payment method event
          //   pr.on("paymentmethod", async (event) => {
          //     try {
          //       await props?.getKeyForApplePay();
          //       // Call your backend to confirm the payment
          //       // const { error } = await stripe.confirmCardPayment(props.clientSecret, {
          //       //   payment_method: event.paymentMethod.id,
          //       // });

          //       // if (error) {
          //       // console.error("Payment failed:", error);
          //       // toast.error("Payment failed: "+ error);
          //       // event.complete("fail");
          //       // } else {
          //       console.log("Payment succeeded!");
          //       // toast.success("Payment succeeded!");
          //       event.complete("success");
          //       //   // Call the handleCheckout method after successful payment
          //       //   props.handleCheckout();
          //       // }
          //     } catch (err) {
          //       console.error("Error handling payment:", err);
          //       toast.error("Error handling payment:", err);
          //       event.complete("fail");
          //     }
          //   });
          // } else {
          //   setIsApplePayAvailable(false);
        }
      });
    }
  }, [stripe]);

  const buttonStyle = {
    paymentRequest,
    style: {
      paymentRequestButton: {
        type: "default", // "default", "buy", "book", "donate", etc.
        theme: "light", // "dark", "light", "light-outline".
        height: "48px", // Adjust the height of the button.
      },
    },
  };

  return (
    <Box>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width="100%"
          height={48}
          sx={{ borderRadius: "12px" }}
        />
      ) : isApplePayAvailable ? (
        <Box
          sx={{
            border: "1px solid black",
            borderRadius: "12px",
            overflow: "hidden",
            display: "inline-block",
            width: "100%",
            cursor: "pointer",
          }}
        >
          <PaymentRequestButtonElement options={buttonStyle} />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

export default ApplePayComponent;
